<template>
  <Page
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <ActivityComponent
      :id-types="id_types"
      :headers="headers"
      @total="setTotal"
      @loading="setLoading"
    />
  </Page>
</template>

<script>
import ActivityComponent from "@/components/modules/Activities/Index";
export default {
  components: {
    ActivityComponent
  },
  data() {
    return {
      id_types: [
        {
          key: "user",
          label: this.$tc("customer", 1)
        },
        {
          key: "order",
          label: this.$tc("order", 1)
        },
        {
          key: "pack",
          label: this.$tc("pack", 1)
        },
        {
          key: "product",
          label: this.$tc("product", 1)
        },
        {
          key: "sku",
          label: this.$tc("sku", 1)
        },
        {
          key: "voucher",
          label: this.$tc("voucher", 1)
        }
      ],
      headers: [  
        {
          value: "activity_id",
          label: this.$t("id"),
          width: "100",
          sortable: true
        }, 
        {
          value: "activity",
          label: this.$tc("activity", 1),
          width: "250",
          sortable: true
        },
        {
          value: "tld",
          label: this.$tc("tld", 1),
          width: "80"
        },
        {
          value: "agent",
          label: this.$tc("agent", 1),
          width: "160"
        },     
        {
          value: "details",
          label: this.$t("details")
        },     
        {
          value: "ip",
          label: this.$t("ip"),
          width: "160",
          sortable: true
        },
        {
          value: "created_at",
          label: this.$t("date"),
          width: "180",
          sortable: true
        },
      ],
      total: 0,
      loading: true
    };
  },
  created() {
    this.updateTitle(this.$tc("activity", 2));
  },
  methods: {
    setTotal(val) {
      this.total = val;
    },
    setLoading(val) {
      this.loading = val;
    }
  }
};
</script>
