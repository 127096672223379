<template>
  <Card
    v-if="$can('read', permission)"
    content-class="c-table"
  >
    <div class="columns is-mobile is-multiline m-0 pt-2">
      <div class="column is-2-tablet is-6-mobile">
        <b-autocomplete-validation
          v-model="selected_type"
          size="is-small"
          width="100%"
          fit-content
          :label="$tc('activity', 1)"
          :data="types"
          :placeholder="$t('choose')"
          vid="type"
          item-text="label"
          field="id"
        />
      </div>
      <div class="column is-2-tablet is-6-mobile">
        <b-select-validation
          v-model="selected_agent"
          vid="type"
          :label="$tc('agent', 1)"
          size="is-small"
          hide-details
        >
          <option
            v-for="opt in agents"
            :key="opt.id"
            :value="opt.id"
          >
            {{
              opt.name
            }}
          </option>
        </b-select-validation>
      </div>
      <div class="column is-2-tablet is-6-mobile">
        <b-input-validation
          v-model="selected_id"
          :placeholder="selectedIDTypeLabel"
          input-type="number"
          :label="selectedIDTypeLabel || $t('id')"
          size="is-small"
          hide-details
          icon-right="filter"
          icon-right-clickable
          @icon-right-click="showDrop()"
        />
        <b-dropdown
          :ref="`filter_drop`"
          v-model="selectedIDType"
          aria-role="list"
          class="is-flex"
        >
          <b-dropdown-item
            v-for="item in idTypes"
            :key="item.key"
            aria-role="listitem"
            :value="item.key"
            @click="changeFilter(item.key, item.label)"
          >
            {{ item.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        class="column is-6-tablet is-12-mobile is-flex is-justify-content-flex-end"
      >
        <DatesPicker @change="changeDates" />
      </div>
    </div>
    <hr class="my-0">

    <b-table
      ref="table"
      :data="list"
      :loading="loading"
      hoverable
      :total="total"
      sortable
      :default-sort="defaultSort"
      :default-sort-direction="defaultOrder"
      backend-sorting
      @sort="sortPressed"
    >
      <template v-for="h in headers">
        <b-table-column
          :key="h.value"
          v-slot="props"
          :field="h.value"
          :label="h.label"
          :width="h.width"
          :sortable="h.sortable"
          header-class="sticky-header"
        >
          <template v-if="h.value == 'agent'">
            {{ props.row.agent ? props.row.agent.name : "-" }}
          </template>
          <template v-else-if="h.value == 'activity'">
            {{ props.row.label }}
          </template>
          <template v-else-if="h.value == 'created_at'">
            <span>
              {{ props.row.created_at.formatted }}
              <span class="has-text-grey-light">
                {{ props.row.created_at.time }}
              </span>
            </span>
          </template>
          <template v-else-if="h.value == 'activity_id'">
            <span
              v-if="props.row.id"
              v-html="props.row.id"
            />
            <span v-else>-</span>
          </template>
          <template v-else-if="h.value == 'details'">
            <span
              v-if="props.row.details"
              v-html="props.row.details"
            />
            <span v-else>-</span>
          </template>
          <template v-else-if="h.value == 'ip'">
            <template>
              {{ props.row.ip }}
            </template>
            <b-tooltip
              :label="props.row.user_agent"
              position="is-bottom"
              multilined
            >            
              <b-icon
                icon="information-variant-circle-outline"
                type="is-info"
                size="is-small"
              />
            </b-tooltip>
          </template>
          <template v-else-if="h.value == 'tld'">
            {{ props.row.tld && props.row.tld.label || "-" }}
          </template>
        </b-table-column>
      </template>

      <EmptyBlock
        slot="empty"
        icon="timeline-text"
      />
    </b-table>
    <Pagination
      :per-page="perPage"
      :per-pages="perPages"
      :total="total"
      :current-page="currentPage"
      @update-per-page="val => (perPage = val)"
      @update-current-page="val => (currentPage = val)"
    />
  </Card>
</template>

<script>
import debounce from "debounce";
import DatesPicker from "@/components/forms/elements/DatesPicker";

export default {
  components: {
    DatesPicker
  },
  props: {
    idTypes: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    endpoint: {
      type: String,
      default: "activities"
    },
    permission: {
      type: String,
      default: "settings.activities"
    },
    userId: {
      type: Number,
      default: null,
    },
    defaultSort: {
      type: String,
      default: "created_at"
    },
    defaultOrder: {
      type: String,
      default: "desc"
    }
  },
  data() {
    return {
      loading: true,
      loaded: false,
      selected_type: null,
      selected_agent: null,
      selected_id: null,
      selectedIDType: null,
      selectedIDTypeLabel: null,
      currentPage: 1,
      list: [],
      types: [],
      agents: [],
      total: 0,
      singleSorting: {},
      perPage: 50,
      perPages: [50, 200, 500],
      date_filter: {
        from: null,
        to: null
      }
    };
  },
  watch: {
    selected_type() {
      this.getActivities();
    },
    selected_agent() {
      this.getActivities();
    },
    selected_id: debounce(function() {
      this.getActivities();
    }, 200),
    currentPage() {
      this.getActivities();
    },
    perPage() {
      this.getActivities();
    },
    loading(val) {
      this.$emit("loading", val);
    }
  },
  created() {
    let firstTypeId = this.idTypes[0];
    this.selectedIDType = firstTypeId.key;
    this.selectedIDTypeLabel = firstTypeId.label;
    this.singleSorting = {field: this.defaultSort, order: this.defaultOrder};
  },
  methods: {
    changeFilter(key, label) {
      this.selectedIDType = key;
      this.selectedIDTypeLabel = label;
      if (this.selected_id) {
        this.getActivities();
      }
    },
    showDrop() {
      this.$refs[`filter_drop`].toggle();
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      this.getActivities();
    },
    sortPressed(field, order) {
      this.singleSorting = { field, order };
      this.getActivities();
    },
    getActivities() {
      if (!this.$can("read", this.permission)) return;
      this.loading = true;

      let api_params = `?page=${this.currentPage}&per_page=${this.perPage}&date_from=${this.date_filter.from}&date_to=${this.date_filter.to}`;

      if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];

      if (this.selected_id) {
        searchArr.push({
          id: this.selectedIDType,
          operator: "is",
          selected: this.selected_id
        });
      }
      if (this.selected_type) {
        searchArr.push({
          id: "type",
          operator: "is",
          selected: this.selected_type
        });
      }

      if (this.selected_agent) {
        searchArr.push({
          id: "agent",
          operator: "is",
          selected: this.selected_agent
        });
      }

      if(this.userId) {
        searchArr.push({
          id: "user",
          operator: "is",
          selected: this.userId
        });
      }

      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.$axios
        .get(this.endpoint + api_params)
        .then(res => {
          // this.list = res.data.list;
          let list = res.data.list.reduce((arr, obj) => {
            let array = [];

            if (obj.order_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$t(
                  "num"
                )}</span>: ${obj.order_id}`
              );
            if (obj.user_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$tc(
                  "user",1
                )}</span>: ${obj.user_id}`
              );
            // if (obj.payment_id)
            //   array.push(
            //     `<span class="has-text-weight-semibold">${this.$t(
            //       "payment"
            //     )}</span>: ${obj.payment_id}`
            //   );
            if (obj.pack_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$tc("pack",1)}</span>: ${obj.pack_id}`
              );
            if (obj.prod_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$tc(
                  "product",
                  1
                )}</span>: ${obj.prod_id}`
              );
            if (obj.prod_ref_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$tc(
                  "sku",
                  1
                )}</span>: ${obj.prod_ref_id}`
              );
            if (obj.inventory_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$t(
                  "inventory"
                )}</span>: ${obj.inventory_id}`
              );
            if (obj.voucher_id)
              array.push(
                `<span class="has-text-weight-semibold">${this.$t("voucher")}</span>: ${obj.voucher_id}`
              );

            if (
              typeof obj.details == "object" &&
              Object.prototype.toString.call(obj.details) != "[object Array]" &&
              obj.details != null
            ) {
              for (const [key, value] of Object.entries(obj.details)) {
                array.push(
                  `<span class="has-text-weight-semibold">${this.$tc(key,1)}</span>: ${JSON.stringify(
                    value
                  )
                    .split('"')
                    .join("")
                    .split(",")
                    .join(", ")}`
                );
              }
            } else if (
              typeof obj.details == "object" &&
              Object.prototype.toString.call(obj.details) == "[object Array]" &&
              obj.details != null
            ) {
              array.push(
                JSON.stringify(obj.details)
                  .split('"')
                  .join("")
                  .split(",")
                  .join(", ")
              );
            } else if (obj.details != null) {
              array.push(obj.details);
            }
            obj.details = array.join(" - ");
            
            return [...arr, obj];
          }, []);
          this.list = list;
          if (!this.laoded) {
            this.types = res.data.filters.types || [];
            this.agents = res.data.filters.agents;
            this.laoded = true;
          }
          this.total = res.data.pagination.total;
          this.$emit("total", this.total);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
